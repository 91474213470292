import { graphql } from "gatsby"
import IDataPageProps from "../interfaces/data-page-props"
import SideLayout from "../layouts/side-layout"
import * as React from "react"
import MarkdownBody from "../components/markdown-body"
import Seo from "../layouts/seo"

export default function Page({ data, location }: IDataPageProps) {
  const post = data.post

  return (
    <SideLayout title="Terms" location={location}>
      <div className="mb-16">
        <MarkdownBody html={post.html} className="text-justify" />
      </div>
      <></>
    </SideLayout>
  )
}

export const Head = () => <Seo title="Terms" />

export const pageQuery = graphql`
  query {
    post: markdownRemark(fileAbsolutePath: { regex: "/pages/terms/" }) {
      id
      html
      fields {
        slug
        date
      }
      frontmatter {
        title
      }
    }
  }
`
